.totalContAbout {
  display: flex;
}

.aboutImg {
  width: 35vw;
  max-height: 60vh;
  margin: 0;
}

.descriptionAbout {
  margin-top: 1rem;
  text-align: center;
  font-weight: lighter;
}

.aboutTitle {
  font-size: 3rem;
  padding-bottom: 2rem;
  color: rgb(42, 109, 236);
  text-align: center;
}
@media (min-width: 300px) and (max-width: 900px) {
  .totalContAbout {
    background-image: url(./../../assets/picStand.jpg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
  }

  .totalContAbout::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.5;
    background-color: black;
  }

  .totalContAbout .aboutText {
    position: relative;
    z-index: 1;
    color: white;
  }

  .aboutTitle {
    text-shadow: 1px 1px 0 black;
  }

  .aboutImg {
    display: none;
  }
}
