:root {
  --main-color: rgb(42, 109, 236);
}

a {
  text-decoration: none;
  color: black;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.logo {
  font-family: "Kalam", cursive;
  color: rgb(42, 109, 236);
  margin-left: 0.3rem;
  font-weight: bold;
  font-size: 1.5rem;
  transition: transform 0.5s;
}

.logo:hover {
  transform: scale(1.2);
}

.navigation {
  font-family: "Kalam", cursive;
  font-family: "Noto Sans Display", sans-serif;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 1.3rem;
  transition: transform 0.5s;
  display: inline-block;
}

.navigation:hover {
  transform: scale(1.3);
}

.contactButton {
  font-family: "Noto Sans Display", sans-serif;
  color: white;
  padding: 0.8rem;
  border-radius: 15px;
  background: linear-gradient(to right, var(--main-color) 50%, black 50%) left /
    200%;
  transition: background-position 1s ease;
  display: flex;
  align-items: center;
  margin-left: 3rem;
  cursor: pointer;
  justify-content: center;
}

.contactButton:hover {
  background-position: right;
}

.icon {
  margin-top: 0.4rem;
  margin-right: 0.2rem;
}

.hambMenu {
  display: none;
}

@media (max-width: 1100px) {
  .contactButton {
    margin: 0;
  }
}

@media (max-width: 900px) {
  .hambMenu {
    position: relative;
  }

  .menu {
    position: absolute;
    background-color: white;
    border: 1px solid black;
    border-radius: 15px;
    padding: 5px;
    z-index: 2;
  }
  .hambBtn {
    background-color: transparent;
    border: none;
    color: black;
    font-size: 1rem;
    cursor: pointer;
  }
  .menu ul {
    list-style: none;
    padding: 0.5rem;
    margin: 0;
  }
  .menu li {
    padding: 0.2rem;
  }
  .items {
    display: none;
  }

  .hambMenu {
    display: block;
  }

  .contactButton {
    width: 7 vw;
    height: 2vh;
    font-size: 0.8rem;
  }

  .logo {
    font-size: 1rem;
  }

  .navigationHamb {
    font-family: "Kalam", cursive;
    font-family: "Noto Sans Display", sans-serif;
    font-size: 1rem;
    cursor: pointer;
    transition: transform 0.5s;
    display: inline-block;
  }
}

@media (max-width: 400px) {
  .container {
    display: flex;
    justify-content: space-between;
  }

  .hambBtn {
    font-size: 0.7rem;
  }

  .logo {
    font-size: 0.7rem;
  }
  .contactButton {
    width: 13vw;
    height: 0.8vh;
    font-size: 0.7rem;
  }
}
