.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid black thin;
  border-radius: 15px;
  background-image: url(./../../assets/pattern.svg);
}

.footerTit {
  font-size: 1rem;
  margin-right: 3rem;
  color: rgb(42, 109, 236);
}

.footerBtn {
  font-size: 1.5rem;
  margin: 0 1rem 0 1rem;
  color: rgb(42, 109, 236);
}

@media (max-width: 600px) {
  .footerTit {
    font-size: 0.7rem;
    text-align: center;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .footerBtn {
    font-size: 1rem;
    margin: 0 0.6rem 0 0.6rem;
  }
  .footerItem{
    font-size: 0.6rem;
  }
}
