.cardContainer {
  display: flex;
}

.description {
  text-align: center;
}

.services {
  margin-left: 2rem;
  text-align: center;
  color: rgb(42, 109, 236);
}

.title {
  margin-left: 2rem;
  text-align: center;
  font-size: 3rem;
}

.cont {
  display: flex;
  text-align: center;
}

.cardDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem;
  transition: transform 0.5s;
}

.cardDiv:hover {
  transform: scale(1.2);
}

.cardDiv:hover span:first-child {
  transition: transform 0.5s;
  transform: rotateY(360deg);
}
.cardIcon {
  transition: transform 0.5s;
}

.cardTit {
  color: black;
}

@media (min-width: 300px) and (max-width: 600px) {
  .title {
    font-size: 1.5rem;
  }

  .cardTit {
    font-size: 1.3rem;
  }

  .description {
    font-size: 0.8rem;
  }

  .cardIcon {
    font-size: 4rem;
  }

  .cardDiv {
    margin: 1rem;
  }
}

@media (max-width: 800px) {
  .cont {
    display: flex;
    text-align: center;
    flex-direction: column;
  }

  .cardDiv {
    margin: 0.3rem;
  }


}
