.formCont {
  display: flex;
  padding-bottom: 3rem;
}

.formIntro {
  flex-basis: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 70%;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.formh1 {
  color: rgb(42, 109, 236);
  text-align: center;
}

.formh2 {
  text-align: center;
  padding: 0.5rem;
}
.name {
  width: 40vw;
}

.message {
  height: 15vh;
  width: 40vw;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.contBtnForm {
  display: flex;
  justify-content: center;
  width: 40vw;
}

.formBtn {
  font-family: "Noto Sans Display", sans-serif;
  color: white;
  padding: 0.8rem;
  border: none;
  border-radius: 15px;
  background: linear-gradient(to right, var(--main-color) 50%, black 50%) left /
    200%;
  transition: background-position 1s ease;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.formBtn:hover {
  background-position: right;
}

.newDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.closeMessage {
  font-family: "Noto Sans Display", sans-serif;
  color: white;
  border: none;
  border-radius: 15px;
  background: linear-gradient(to right, var(--main-color) 50%, black 50%) left /
    200%;
  transition: background-position 1s ease;
  cursor: pointer;
  height: 2rem;
  margin-left: 2rem;
}

@media (max-width: 992px) {
  .formh1 {
    font-size: 2rem;
    margin: 0;
    padding: 0;
  }

  .formh2 {
    font-size: 1.3rem;
    margin: 0;
    padding: 0.5rem;
  }
}

@media (max-width: 710px) {
  .formh1 {
    font-size: 2rem;
  }

  .formh2 {
    font-size: 1rem;
  }
}
