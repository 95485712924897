.certCont {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  padding: 2rem;
}

.certTit {
  color: rgb(42, 109, 236);
}

.certCard {
  border: solid black;
  border-radius: 15px;
  flex-basis: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  background-image: url(./../../assets/pattern.svg);
}

.imageDiv {
  border: solid black;
  border-radius: 15px;
  padding: 0.2rem;
  background-color: gray;
  margin-bottom: 0.5rem;
  height: 38vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.certImg {
  width: 20vw;
  height: 35vh;
  justify-content: center;
}

.certDesc {
  text-align: center;
  font-size: 1.3rem;
  font-weight: lighter;
  margin: 0;
}

@media (max-width: 992px) {
  .certCont {
    display: flex;
    flex-direction: column;
  }

  .imageDiv {
    width: 50vw;
  }

  .certImg {
    width: 40vw;
  }
}

@media (max-width: 688px) {
  .certCont {
    display: flex;
    flex-direction: column;
  }

  .imageDiv {
    width: 50vw;
    height: 30vh;
  }

  .certImg {
    width: 40vw;
    height: 27vh;
  }
  .certTit {
    font-size: 1.3rem;
  }
  .certDesc {
    font-size: 1rem;
  }
}

@media (max-width: 415px) {
  .imageDiv {
    height: 20vh;
  }

  .certImg {
    height: 19vh;
  }
}
