:root {
  --main-color: rgb(42, 109, 236);
}

.contIcons {
  display: flex;
  background-image: url(./../../assets/pattern.svg);
  margin-top: 4rem;
  margin-bottom: 4rem;
  border: solid black;
  border-radius: 15px;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem;
  transition: transform 0.5s;
}

.cardFive {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem;
  transition: transform 0.5s;
}

.card:hover {
  transform: scale(1.3);
}

.cardFive:hover {
  transform: scale(1.3);
}
.cardDescription {
  text-align: center;
  font-size: 1rem;
}

.cardTitle {
  color: rgb(42, 109, 236);
}

.cardIcon {
  color: rgb(42, 109, 236);
  font-size: 3rem;
}

@media (min-width: 501px) and (max-width: 992px) {
  .contIcons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    justify-items: center;
    align-items: center;
  }

  .cardFive {
    grid-column: 1 / span 2;
    margin: 0;
    width: 40vw;
    height: 45vh;
  }

  .card {
    margin: 0;
    width: 30vw;
    height: 45vh;
  }
  .cardIcon{
    font-size: 5rem;
  }
}

@media (max-width: 500px) {
  .contIcons {
    display: flex;
    flex-direction: column;
  }
  .card:hover {
    transform: scale(1.1);
  }
  .cardFive:hover {
    transform: scale(1.1);
  }

  .card {
    height: 27vh;
  }

  
}

